// Generated by Framer (013b13c)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-KUo2H"

const variantClassNames = {Sep0ago8d: "framer-v-u4b8ba"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Sep0ago8d", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-u4b8ba", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Sep0ago8d"} ref={refBinding} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 102.5, intrinsicWidth: 128, loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + -8)), pixelHeight: 205, pixelWidth: 256, src: "https://framerusercontent.com/images/JvvSAncNCm3duaHZy1HG1Y6znVg.gif"}} className={"framer-v6j23b"} data-framer-name={"Unicorn-16249 256"} layoutDependency={layoutDependency} layoutId={"lWf2_xx4g"}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KUo2H.framer-1wcg8uv, .framer-KUo2H .framer-1wcg8uv { display: block; }", ".framer-KUo2H.framer-u4b8ba { height: 200px; position: relative; width: 200px; }", ".framer-KUo2H .framer-v6j23b { aspect-ratio: 1.248780487804878 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 199px); left: -20px; overflow: visible; position: absolute; right: -28px; top: -8px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHOwpQmq_t: React.ComponentType<Props> = withCSS(Component, css, "framer-KUo2H") as typeof Component;
export default FramerHOwpQmq_t;

FramerHOwpQmq_t.displayName = "unicorn-cursor";

FramerHOwpQmq_t.defaultProps = {height: 200, width: 200};

addFonts(FramerHOwpQmq_t, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})